import { Component, inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MenuItem, Modulo, Tile } from '../../shared/classes/menu-item';
import { HeaderService } from '../../shared/services/header.service';

@Component({
  selector: 'app-mitablero',
  standalone: true,
  imports: [MatCardModule,MatGridListModule,MatIconModule,CommonModule,MatButtonModule],
  templateUrl: './mitablero.component.html',
  styleUrl: './mitablero.component.scss'
})
export class MitableroComponent implements OnInit{

  private router = inject(Router);
  private headerService = inject(HeaderService);
  module_title = '';

  ngOnInit(): void {
    this.setTitle();
  }
  private setTitle(): void {
    this.headerService.setTitle('Mi tablero');
  }

  lista_menu: MenuItem[] = [
    { name: 'Crear Personal', route: 'personal' },
    { name: 'Reportes', route: '' },
    { name: 'Mostrar todo', route: '' },
  ];
  fav_tiles: Tile[] = [
    { text: 'Administracion del Personal', icon: 'star', route: 'personal' },
    { text: 'Crear nuevo personal', icon: 'star', route: '' },
    { text: 'Nomina acotada', icon: 'star', route: '' },
    { text: 'Reportes', icon: 'star', route: '' },
  ];
  ult_acc_tiles: Tile[] = [
    { text: 'Administracion del Personal', icon: 'restore', route: '' },
    { text: 'Crear nuevo personal', icon: 'restore', route: '' },
    { text: 'Nomina acotada', icon: 'restore', route: '' },
  ];
  modulos: Modulo[] = [
    {
      name: 'SIRHU',
      sigla: 'SU',
    },
    {
      name: 'SIFOR',
      sigla: 'SR',
    },
    {
      name: 'SIORG',
      sigla: 'SG',
    },
    {
      name: 'SILOL',
      sigla: 'SG',
    },
    {
      name: 'SIPAM',
      sigla: 'SM',
    },
    {
      name: 'SILCAPER',
      sigla: 'SR',
    },
    {
      name: 'SMLAM',
      sigla: 'SM',
    },
    {
      name: 'SIRMA-AMEA',
      sigla: 'SA',
    },
    {
      name: 'CAE',
      sigla: 'CE',
    },
    {
      name: 'SIRMA-CELULARES',
      sigla: 'ss',
    },
    {
      name: 'SIGET',
      sigla: 'ST',
    },
    {
      name: 'SIPAP',
      sigla: 'SP',
    },
  ];

  navegarFav(item: Tile) {
    if (item.route != '') {
      this.router.navigate([item.route]);
    } else return;
  }

  navegar(item: MenuItem) {
    if (item.route != '') {
      this.router.navigate([item.route]);
    } else return;
  }


}
