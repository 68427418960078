

<mat-grid-list cols="3" rowHeight="100px" >
  <h1 class="fav_title" >Favoritos</h1>
        <mat-grid-tile *ngFor="let tile of fav_tiles; let i = index">
          <button mat-button class="fav_btn" (click)="navegarFav(tile)">{{tile.text}}
            <mat-icon class="star_icon" iconPositionEnd >{{tile.icon}}</mat-icon>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="3" rowHeight="100px">
        <h1 class="fav_title" >Útimos accesos</h1>
        <mat-grid-tile *ngFor="let tile of ult_acc_tiles; let i = index">
          <button mat-button class="fav_btn">{{tile.text}} <mat-icon iconPositionEnd
            class="restore_icon">{{tile.icon}}</mat-icon></button>
          </mat-grid-tile>
        </mat-grid-list>



